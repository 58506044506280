<template>
  <div class="w-full justify-between flex flex-row">
    <div class="w-full border">
      <vue-signature-pad
        :id="id"
        :width="width"
        :height="height"
        :ref="reference"
        :options="options"
      ></vue-signature-pad>
    </div>
    <div class="items-end flex flex-col">
      <el-button @click="save" size="small" title="Guardar" class="w-12 mb-1" type="primary">
        <i class="bx bx-save" />
      </el-button>
      <el-button @click="undo" size="small" title="Deshacer" class="w-12">
        <i class="bx bx-arrow-back" />
      </el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SaSignature',
  props: {
    id: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: () => '100%',
    },
    height: {
      type: String,
      default: () => '250px',
    },
  },
  data() {
    return {
      options: {
        penColor: "#c0f",
      },
    }
  },
  computed: {
    reference() {
      return `signature-pad-${this.id}`;
    },
  },
  methods: {
    undo() {
      this.$refs[this.reference].undoSignature();
    },
    save() {
      const { isEmpty, data } = this.$refs[this.reference].saveSignature();
      if (!isEmpty) {
        this.$emit('signature:update', data);
      }
    },
  }
}
</script>